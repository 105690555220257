<template>
  <div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.user_task.target_registry_id') }}
      </div>
      <registry-select-tree
        :cache="true"
        type="registry"
        :value="{id: parseInt(extensions.target_registry_id) || null}"
        @change="onExtensionChangeWithAlias('target_registry_id', $event)"
      />
    </div>
    <div class="property" v-if="extensions.target_registry_id">
      <div class="label">
        {{ $t('logic_editor_v2.element_types.command') }}
      </div>
      <logic-tree-elements
        :key="extensions.target_registry_id || 0"
        :value="parseInt(extensions.command_id) || null"
        :placeholder="$t('logic_editor_v2.element_types.command')"
        :object-id="parseInt(extensions.target_registry_id) || null"
        @input="$emit('change-extension', { name: 'command_id', value: $event })"
        element-type="command"/>
    </div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.service_task.run_command_on_record') }}
      </div>
      <el-input size="mini" :value="extensions['record.value']" @input="changeRecordGetter"></el-input>
    </div>
    <variables-block
      :title="$t('process_editor.element_properties.service_task.input_variables')"
      :extensions="extensions"
      name="input_variables"
      @change-extension="$emit('change-extension', $event)"
      @remove-extension="$emit('remove-extension', $event)"
    />
  </div>
</template>

<script>
import mixins from '../../mixins'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import VariablesBlock
  from '@/services/BPMNEditor/infrastructure/components/PropertiesPanel/Shared/VariablesBlock/index.vue'

export default {
  name: 'ExecuteCommand',
  components: { VariablesBlock, RegistrySelectTree },
  mixins: [mixins],
  methods: {
    changeRecordGetter (value) {
      this.$emit('change-extension', { name: 'record.value', value: value })
      this.$emit('change-extension', { name: 'record.type', value: 'parameter' })
    }
  }
}
</script>
